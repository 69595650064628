import type { tokenType } from "./token-type";

const colors: tokenType = {
  "mkm-yellow-darker": {
    "value": "#cca300"
  },
  "mkm-yellow-dark": {
    "value": "#e6b800"
  },
  "mkm-yellow-default": {
    "value": "#ffcc00"
  },
  "mkm-yellow-light": {
    "value": "#ffdb4d"
  },
  "mkm-yellow-lighter": {
    "value": "#fff5cc"
  },
  "mkm-blue-darker": {
    "value": "#0e285a"
  },
  "mkm-blue-dark": {
    "value": "#102e67"
  },
  "mkm-blue-default": {
    "value": "#143981"
  },
  "mkm-blue-light": {
    "value": "#1e55c0"
  },
  "mkm-blue-lighter": {
    "value": "#e0e9fa"
  },
  "charcoal-darker": {
    "value": "#242424"
  },
  "charcoal-dark": {
    "value": "#292929"
  },
  "charcoal-default": {
    "value": "#333333"
  },
  "charcoal-light": {
    "value": "#5c5c5c"
  },
  "charcoal-lighter": {
    "value": "#c2c2c2"
  },
  "grey-darker": {
    "value": "#999999"
  },
  "grey-dark": {
    "value": "#aeaeae"
  },
  "grey-default": {
    "value": "#dadada"
  },
  "grey-light": {
    "value": "#e9e9e9"
  },
  "grey-lighter": {
    "value": "#f8f8f8"
  },
  "neutral-light": {
    "value": "#E9E9E9"
  },
  "neutral-lighter": {
    "value": "#F8F8F8"
  },
  "red-darker": {
    "value": "#7b2726"
  },
  "red-dark": {
    "value": "#ac3736"
  },
  "red-default": {
    "value": "#c3403f"
  },
  "red-light": {
    "value": "#d2706f"
  },
  "red-lighter": {
    "value": "#f8e7e7"
  },
  "green-darker": {
    "value": "#1e593a"
  },
  "green-dark": {
    "value": "#297d51"
  },
  "green-default": {
    "value": "#3bb273"
  },
  "green-light": {
    "value": "#65cc96"
  },
  "green-lighter": {
    "value": "#ecf9f2"
  },
  "orange-darker": {
    "value": "#915307"
  },
  "orange-dark": {
    "value": "#cb740a"
  },
  "orange-default": {
    "value": "#f5a13c"
  },
  "orange-light": {
    "value": "#f8b86d"
  },
  "orange-lighter": {
    "value": "#fdecd8"
  },
  "blue-darker": {
    "value": "#105f7c"
  },
  "blue-dark": {
    "value": "#1685ae"
  },
  "blue-default": {
    "value": "#33b5e5"
  },
  "blue-light": {
    "value": "#66c7eb"
  },
  "blue-lighter": {
    "value": "#d6f0fa"
  },
  "white": {
    "value": "#ffffff"
  },
  "transparent": {
    "value": "transparent"
  },
}

export default colors;
